import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_website/blog")({
	component: () => (
		<div className="text-lg">
			<div className="[&_table]:bg-white [&_table]:p-2 [&_table]:bg-opacity-80 [&_tbody]:p-2 [&_td]:p-2 [&_th]:p-2 [&_thead]:p-2 [&_table]:border [&_table]:border-gray-200 [&_table]:rounded-md [&_table]:rounded-lg text-lg">
				<Outlet />
			</div>
		</div>
	),
});
