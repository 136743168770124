import { cn } from "@/lib/utils";
import type { EntryValueViewT } from "@/types/database/entries";
import { ScaleRatingValueDisplay } from "../components/ScaleRatingValueDisplay";

// Extended interface for properties that might not be in the base type
interface ExtendedEntryValue extends EntryValueViewT {
	unit?: string;
	duration_time_unit?: string;
}

interface Props {
	entryValue: EntryValueViewT;
	size?: "sm" | "md";
}

const EntryValueSwitch = ({ entryValue, size }: Props) => {
	// Safe access with optional chaining
	if (!entryValue.value) return null;

	// Cast to extended type to access optional properties
	const extendedEntryValue = entryValue as ExtendedEntryValue;

	const inputKey = entryValue.input_key;
	const inputLabel = entryValue.input_label || "";
	const numberValue = entryValue.number_value;
	// Parse number values to ensure consistent display
	const parsedNumberValue =
		numberValue !== null && numberValue !== undefined
			? Number(numberValue)
			: null;
	const textValue = entryValue.text_value;
	const textArray = entryValue.text_array;
	const objectId = entryValue.object_id;

	switch (inputKey) {
		case "number":
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500 dark:text-slate-300">
						{inputLabel}
					</span>
					<div className="flex items-center">
						<span className="font-medium text-slate-700 dark:text-slate-300 text-xl">
							{parsedNumberValue !== null ? parsedNumberValue : "None"}
						</span>
						{parsedNumberValue !== null && extendedEntryValue.unit && (
							<span className="ml-1 text-slate-500 dark:text-slate-400 text-sm">
								{extendedEntryValue.unit}
							</span>
						)}
					</div>
				</div>
			);
		case "long-text":
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500 dark:text-slate-300">
						{inputLabel}
					</span>
					<p className="dark:text-slate-200">{textValue ?? "None"}</p>
				</div>
			);
		case "duration":
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">
						{inputLabel}
					</span>
					<p>
						{numberValue} {extendedEntryValue.duration_time_unit || ""}
					</p>
				</div>
			);
		case "slider":
			return (
				<div>
					<span className="min-w-16 text-slate-700">{inputLabel}</span>
					<div
						style={{ width: "120px" }}
						className="relative bg-slate-300 rounded-full h-2"
					>
						<div
							style={{
								width: `${Number(numberValue) * 12}px`,
							}}
							className="top-0 left-0 absolute bg-indigo-600 rounded-full h-full"
						/>
					</div>
					<p>{String(numberValue)}</p>
				</div>
			);
		case "multiple-choice":
			return (
				<div>
					<span className="min-w-16 text-slate-700">{inputLabel}</span>
					<ul>
						{textArray?.map((item) => (
							<li key={item}>{item}</li>
						))}
					</ul>
				</div>
			);
		case "photo":
			return <img src={objectId || ""} alt={inputLabel || "Uploaded Image"} />;

		case "scale-rating":
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<ScaleRatingValueDisplay
						inputId={entryValue.input_id}
						numberValue={numberValue}
						inputLabel={inputLabel}
					/>
				</div>
			);
		case "foot-photos":
			return <p>FOOT PHOTOS</p>;

		// Custom Inputs
		case "pain-slider":
			return (
				<div className="flex flex-col gap-2">
					<p
						className={cn(
							"dark:text-slate-200",
							size === "sm" ? "text-md" : "text-xl",
						)}
					>
						{inputLabel} {String(numberValue)}/10
					</p>
					<div
						style={{ width: "120px" }}
						className="relative bg-slate-300 rounded-full h-2"
					>
						<div
							style={{
								width: `${Number(numberValue) * 12}px`,
							}}
							className="top-0 left-0 absolute bg-indigo-600 rounded-full h-full"
						/>
					</div>
				</div>
			);

		case "glucose-slider":
			return (
				<div className="flex flex-col gap-2">
					<p className={size === "sm" ? "text-md" : "text-xl"}>
						Glucose {String(numberValue)}{" "}
					</p>
					<div
						style={{ width: "120px" }}
						className="relative bg-slate-300 rounded-full h-2"
					>
						<div
							style={{
								width: `${(Number(numberValue) * 120) / 30}px`,
							}}
							className={cn(
								"absolute left-0 top-0 h-full rounded-full bg-lime-500",
								Number(numberValue) > 20 && "bg-red-500",
								Number(numberValue) < 5 && "bg-red-500",
							)}
						/>
					</div>
				</div>
			);
		case "yes-no":
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">
						{inputLabel}
					</span>
					<p className="capitalize">{textValue ?? "None"}</p>
				</div>
			);
		default:
			return (
				<div className="p-2 pt-1 border border-slate-300 rounded-md w-full">
					<span className="min-w-16 font-medium text-slate-500">
						{inputLabel}
					</span>
					<p>{textValue ?? "None"}</p>
				</div>
			);
	}
};

export default EntryValueSwitch;
