import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandSeparator,
} from "@/components/ui/command";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ChevronsUpDown, Lock } from "lucide-react";
import React from "react";

import { useFormContext } from "react-hook-form";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";
import type { ProfileT } from "../../../types/database/profiles";
import { useProfileQuery } from "../api/selectProfile";
import useFavouriteStaff from "../hooks/useFavouriteStaff";
import FavouriteUserStar from "./FavouriteUserStar";

export type PatientOptionT = {
	label: string;
	value: string;
};

interface Props {
	label?: string;
	includeUser?: boolean;
	placeholder?: string;
	name: string;
	disabled?: boolean;
	organisationId?: string;
}

function useFilteredUsers(organisationId?: string) {
	const { data: profile } = useProfileQuery();
	const { faves, nonFaves } = useFavouriteStaff();

	const filteredUsers = React.useMemo(() => {
		const filterByOrg = (profiles: ProfileT[]) => {
			if (!organisationId) return profiles;
			return profiles.filter((p) =>
				p?.organisations
					?.map((o) => o.organisation_id)
					.includes(organisationId),
			);
		};

		return {
			currentUser: profile,
			favourites: filterByOrg(faves),
			others: filterByOrg(nonFaves),
		};
	}, [organisationId, profile, faves, nonFaves]);

	return filteredUsers;
}

function UserCommandItem({
	user,
	isFavourite,
	onSelect,
}: {
	user: ProfileT;
	isFavourite?: boolean;
	onSelect: (userId: string) => void;
}) {
	return (
		<div className="flex w-full">
			{isFavourite !== undefined && (
				<FavouriteUserStar userId={user.user_id} favourite={isFavourite} />
			)}
			<CommandItem
				className="w-full"
				value={user.user_id}
				onSelect={() => onSelect(user.user_id)}
			>
				{user.name}
			</CommandItem>
		</div>
	);
}

function FavouriteUsersComboBox({
	disabled,
	label,
	name,
	placeholder,
	organisationId,
	includeUser = false,
}: Props) {
	const [open, setOpen] = React.useState(false);
	const form = useFormContext();
	const { currentUser, favourites, others } = useFilteredUsers(organisationId);

	const handleSelect = React.useCallback(
		(userId: string) => {
			form.setValue(name, userId);
			setOpen(false);
		},
		[form, name],
	);

	const selectedUser = React.useMemo(() => {
		const allUsers = [
			...(currentUser ? [currentUser] : []),
			...favourites,
			...others,
		];
		return allUsers.find((u) => u?.user_id === form.watch(name));
	}, [currentUser, favourites, others, form, name]);

	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="flex flex-col justify-start items-start w-full">
					{label && <FormLabel>{label}</FormLabel>}
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger className="w-full" disabled={disabled} asChild>
							<FormControl>
								<Button
									variant="outline"
									role="combobox"
									size="sm"
									className={cn(
										"h-12 w-full justify-between rounded-md bg-white bg-opacity-100 px-3 text-sm hover:bg-slate-100",
										!field.value && "text-slate-500",
									)}
								>
									{selectedUser?.name || placeholder || "Select user"}
									{disabled ? (
										<Lock className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									) : (
										<ChevronsUpDown className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									)}
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="p-0 w-full">
							<Command>
								<CommandInput placeholder="Search..." className="h-9" />
								<CommandEmpty>No users found.</CommandEmpty>
								<ScrollArea className="h-[300px]">
									{includeUser && currentUser && (
										<>
											<CommandGroup>
												<UserCommandItem
													user={currentUser}
													onSelect={handleSelect}
												/>
											</CommandGroup>
											<CommandSeparator />
										</>
									)}
									{favourites.length > 0 && (
										<>
											<CommandGroup>
												{favourites.map((user) => (
													<UserCommandItem
														key={user.user_id}
														user={user}
														isFavourite={true}
														onSelect={handleSelect}
													/>
												))}
											</CommandGroup>
											<CommandSeparator />
										</>
									)}
									<CommandGroup>
										{others.map((user) => (
											<UserCommandItem
												key={user.user_id}
												user={user}
												isFavourite={false}
												onSelect={handleSelect}
											/>
										))}
									</CommandGroup>
								</ScrollArea>
							</Command>
						</PopoverContent>
					</Popover>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

export default FavouriteUsersComboBox;
