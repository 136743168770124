import { createFileRoute } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";
import { ArrowRight } from "lucide-react";
import React from "react";

const blogPosts = [
	{
		title: "5 Benefits of Remote Patient Monitoring",
		description:
			"Discover how RPM improves health outcomes, reduces costs, increases care access, enhances doctor-patient interactions, and optimizes staff workflows.",
		link: "/blog/5-benefits-remote-patient-monitoring",
	},
	{
		title: "Implementing Decentralised Clinical Trials: A Comprehensive Guide",
		description:
			"Learn how to implement remote clinical trials to improve efficiency, reduce dropout rates, and accelerate recruitment by up to 41%.",
		link: "/blog/decentralised-clinical-trials",
	},
	{
		title: "Digital Health Tracking: A Step-by-Step Implementation Guide",
		description:
			"Follow our five-step framework to successfully implement digital health tracking in your practice with proven workflows and technologies.",
		link: "/blog/digital-health-tracking",
	},
	{
		title: "Remote Patient Monitoring: Cost Savings for Healthcare Providers",
		description:
			"Discover how RPM reduces readmissions by up to 50%, improves staff efficiency, and saves an average of $11,472 per patient annually.",
		link: "/blog/remote-patient-monitoring-cost-savings",
	},
	{
		title: "Efficient Patient Initiated Follow-Up After Orthopaedic Injections",
		description:
			"Learn how Patient Watch can help you save time and money by implementing efficient patient initiated follow-up after orthopaedic injections.",
		link: "/blog/efficient-patient-initiated-follow-up-after-orthopaedic-injections",
	},
];

const BlogPages = () => {
	return (
		<div className="mx-auto px-4 py-12 container">
			<h1 className="mb-8 font-bold text-3xl text-center">Our Blog</h1>
			<div className="gap-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
				{blogPosts.map((post) => (
					<div
						key={post.link}
						className="flex flex-col bg-white shadow-sm hover:shadow-md p-6 border border-gray-200 rounded-lg transition-shadow"
					>
						<h2 className="mb-3 font-semibold text-gray-800 text-xl">
							{post.title}
						</h2>
						<p className="flex-grow mb-4 text-gray-600">{post.description}</p>
						<Link
							to={post.link}
							className="inline-flex items-center font-medium text-indigo-600 hover:text-indigo-800"
						>
							Read More
							<ArrowRight size={20} className="ml-1" />
						</Link>
					</div>
				))}
			</div>
		</div>
	);
};

export const Route = createFileRoute("/_website/blog/")({
	component: BlogPages,
});
