import { supabaseClient } from "@/lib/supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { createProfileQueryKey } from "../../profile/api/selectProfile";

const tryDemo = async (role: "clinician" | "patient") => {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-try-demo",
		{
			body: { role: role },
		},
	);
	if (error) throw error;
	return data;
};

export const useTryDemoMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["try-demo"],
		mutationFn: (role: "clinician" | "patient") => {
			toast.info("Logging in");
			return tryDemo(role);
		},
		onError: () => {
			toast.error("Error logging in");
		},
		onSuccess: (data) => {
			toast.success("Login successful");
			// Redirect to the magic link
			const actionLink = data.properties.action_link;
			// Replace IP with localhost if needed
			window.location.href = actionLink;
		},
		onSettled: (data) => {
			queryClient.refetchQueries({ queryKey: ["user"] });
			queryClient.refetchQueries({
				queryKey: createProfileQueryKey(data.user.id),
			});
		},
	});
};
