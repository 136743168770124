import CardGrid from "@/components/common/CardGrid";
import { ui } from "@/components/ui";
import DesktopMobile from "@/features/website/landing/assets/desktop-mobile-patient-watch.png";
import { createFileRoute } from "@tanstack/react-router";
import { Download, ExternalLink } from "lucide-react";
import { Helmet } from "react-helmet";

const DigitalHealthTracking = () => {
	return (
		<>
			<Helmet>
				<title>
					Digital Pain Diaries: Reducing Unnecessary Follow-ups by 91% | Patient
					Watch
				</title>
				<meta
					name="description"
					content="Learn how digital pain diaries can reduce unnecessary orthopedic follow-up appointments by 91% and save £19,000 per 100 patients. Based on Royal Surrey Hospital research."
				/>
				<meta
					name="keywords"
					content="digital pain diary, patient-initiated follow-up, PIFU, orthopedic injections, healthcare efficiency, cost savings, patient care"
				/>

				{/* Open Graph tags for social sharing */}
				<meta
					property="og:title"
					content="Digital Pain Diaries: Reducing Unnecessary Follow-ups by 91%"
				/>
				<meta
					property="og:description"
					content="Learn how digital pain diaries can reduce unnecessary orthopedic follow-up appointments by 91% and save £19,000 per 100 patients."
				/>
				<meta property="og:image" content={DesktopMobile} />

				{/* Twitter Card tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta
					name="twitter:title"
					content="Digital Pain Diaries: Reducing Unnecessary Follow-ups by 91%"
				/>
				<meta
					name="twitter:description"
					content="Learn how digital pain diaries can reduce unnecessary orthopedic follow-up appointments by 91% and save £19,000 per 100 patients."
				/>

				<meta name="robots" content="index, follow" />
				<meta name="author" content="Patient Watch" />

				<link
					rel="canonical"
					href="https://patient-watch.com/blog/digital-pain-diaries-reduce-follow-ups"
				/>
			</Helmet>

			<div className="flex flex-col gap-4 mx-auto my-24 px-4 max-w-xl prose prose-lg">
				<h1 className="font-bold text-3xl" id="digital-pain-diaries">
					📱 Efficient Patient-Initiated Follow-Up After Orthopaedic Injections
				</h1>
				<ui.Card className="mb-8">
					<ui.CardHeader>
						<ui.CardTitle
							id="efficient-patient-initiated-follow-up-after-orthopaedic-injections"
							tabIndex={-1}
						>
							📱 Efficient Patient-Initiated Follow-Up After Orthopaedic
							Injections
						</ui.CardTitle>
						<ui.CardDescription>
							<div className="bg-blue-50 my-2 p-4 border border-blue-200 rounded-lg">
								<p className="mt-0 mb-0 text-sm italic">
									Based on research by Ka Siu Fan, Iqraa Haq, Ali Taha, Andrew
									Carne, Chintu Gademsetty, and Matthew Solan from Royal Surrey
									County Hospital and University of Surrey
								</p>
								<div className="flex gap-4 mt-3 text-gray-600 text-xs">
									<a
										href="https://link.springer.com/article/10.1007/s00590-025-04193-9"
										target="_blank"
										rel="noopener noreferrer"
										className="inline-flex items-center hover:text-indigo-600"
									>
										<ExternalLink className="mr-1 w-3 h-3" />
										View research paper
									</a>
									<a
										href="/documents/efficient-patient-initiated-follow-up-after-orthopaedic-injections.pdf"
										download
										className="inline-flex items-center hover:text-indigo-600"
									>
										<Download className="mr-1 w-3 h-3" />
										PDF version
									</a>
								</div>
							</div>
						</ui.CardDescription>
					</ui.CardHeader>
					<ui.CardContent className="">
						<p>
							<strong>
								"91% of follow-up appointments after orthopedic injections are
								unnecessary."
							</strong>{" "}
							This striking finding from a Royal Surrey County Hospital study
							reveals how digital pain diaries are transforming patient care. By
							empowering patients to initiate their own follow-ups, healthcare
							providers can save £9,500-£19,000 per 100 patients annually while
							delivering better, more personalized care. Here's the full story:
						</p>

						<ul>
							<li>
								<strong>🏥 The Problem:</strong> Standard 6-8 week follow-ups
								after injections are often unnecessary
							</li>
							<li>
								<strong>📊 The Solution:</strong> Pain diaries enable patients
								to request appointments only when needed
							</li>
							<li>
								<strong>💰 The Savings:</strong> Up to £19,000 saved per 100
								patients through avoided appointments
							</li>
							<li>
								<strong>👨‍⚕️ Better Care:</strong> More personalized treatment
								based on individual pain patterns
							</li>
						</ul>
					</ui.CardContent>
				</ui.Card>

				<h2 className="mb-4 font-bold text-indigo-900 text-2xl">
					🚫 The Challenge with Standard Follow-Ups
				</h2>
				<div className="mb-12">
					<p>
						Foot and ankle injections are commonly used to diagnose and treat
						conditions like tendinopathy and arthritis. These injections combine
						local anesthetic (for immediate pain relief) and corticosteroids
						(for longer-lasting anti-inflammatory effects). However, the
						traditional approach to follow-up care has significant drawbacks:
					</p>

					<div className="gap-6 grid md:grid-cols-2 my-8">
						<div className="bg-gray-50 p-5 border border-gray-200 rounded-lg">
							<h3 className="mt-0 mb-2 font-semibold">🗓️ Current Practice</h3>
							<ul className="space-y-2 pl-5 list-disc">
								<li>85% of clinicians schedule routine 6-8 week follow-ups</li>
								<li>83% of these appointments are unnecessary</li>
								<li>Duration of pain relief is highly variable</li>
								<li>One-size-fits-all approach wastes resources</li>
								<li>Costs £100-200 per appointment</li>
							</ul>
						</div>
						<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
							<h3 className="mt-0 mb-2 font-semibold">📊 Study Findings</h3>
							<ul className="space-y-2 pl-5 list-disc">
								<li>Only 9% of patients needed follow-up within 6-8 weeks</li>
								<li>Median time to follow-up was 117 days</li>
								<li>43% required no follow-up at all</li>
								<li>57% eventually requested follow-up</li>
								<li>£9,500-£19,000 saved per 100 patients</li>
							</ul>
						</div>
					</div>

					<p>
						The study examined 104 patients who received foot and ankle
						injections over a 12-month period. By tracking when patients
						actually needed follow-up care, researchers discovered that the
						standard 6-8 week appointment timeframe was unnecessary for 91% of
						patients.
					</p>
				</div>

				<h2 className="mb-4 font-bold text-indigo-900 text-2xl">
					📝 How Pain Diaries Transform Follow-Up Care
				</h2>
				<div className="mb-12">
					<p>
						The Royal Surrey study used a simple paper-based pain diary that
						allowed patients to record their pain levels on a visual analog
						scale. This approach had several advantages:
					</p>

					<div className="my-6">
						<img
							src={DesktopMobile}
							alt="Patient Watch digital pain diary interface showing visual analog scale"
							className="shadow-sm mx-auto rounded-lg w-full max-w-lg"
							loading="lazy"
							width="700"
							height="400"
						/>
						<p className="mt-2 text-gray-600 text-sm text-center">
							Example of a pain diary used in the study
						</p>
					</div>

					<ul>
						<li>
							<strong>🔍 Better Diagnostic Information:</strong> Detailed pain
							records help clinicians understand exactly when and how the
							injection affected symptoms
						</li>
						<li>
							<strong>⏱️ Appropriate Timing:</strong> Follow-ups occur when
							clinically necessary, not based on arbitrary schedules
						</li>
						<li>
							<strong>⏱️ Informed Decision-Making:</strong> Pain patterns guide
							future treatment decisions (repeat injections, alternative sites,
							or surgical options)
						</li>
						<li>
							<strong>👨‍👩‍👧‍👦 Patient Empowerment:</strong> Patients become
							active participants in their care journey
						</li>
					</ul>
				</div>

				<div className="">
					<h2>Patient Watch Digital Pain Diary Solution</h2>
					<p>
						While the study used paper diaries, the researchers noted that
						digital solutions could address many of the limitations they
						encountered. This is where{" "}
						<strong>Patient Watch's Digital Pain Diary</strong> offers
						significant advantages:
					</p>

					<div className="bg-indigo-50 shadow-sm p-6 border border-indigo-200 rounded-lg">
						<h3 className="mb-3 font-bold text-indigo-800 text-xl">
							🚀 How Patient Watch Enhances PIFU
						</h3>

						<CardGrid
							items={[
								{
									icon: "⏰",
									title: "Real-Time Tracking",
									description:
										"Automated reminders ensure consistent pain recording",
								},
								{
									icon: "🔔",
									title: "Smart Alerts",
									description:
										"Notifies clinicians when pain patterns indicate need for review",
								},
								{
									icon: "📊",
									title: "Advanced Analytics",
									description:
										"Visualizes pain trends for better clinical decision-making",
								},
							]}
							renderItem={(item) => (
								<div className="bg-white shadow-sm p-4 rounded-md">
									<span className="block mb-2 text-3xl text-center">
										{item.icon}
									</span>
									<h4 className="mb-1 font-semibold text-center">
										{item.title}
									</h4>
									<p className="text-sm text-center">{item.description}</p>
								</div>
							)}
						/>

						<p className="mb-2">
							Patient Watch's digital solution addresses the key limitations
							identified in the study:
						</p>

						<ul className="mb-4">
							<li>
								<strong>📱 Accessibility:</strong> Mobile app ensures patients
								can record pain anywhere, anytime
							</li>
							<li>
								<strong>🔄 Consistency:</strong> Automated reminders improve
								compliance with regular recording
							</li>
							<li>
								<strong>👥 Vulnerable Patients:</strong> Identifies
								non-responders who may need additional support
							</li>
							<li>
								<strong>📈 Data Quality:</strong> Prevents retrospective
								completion with timestamped entries
							</li>
							<li>
								<strong>🔒 Data Sharing:</strong> Securely shares information
								with the entire care team
							</li>
						</ul>

						<ui.Button asChild className="w-full md:w-auto">
							<a href="/solutions/digital-pain-diary">Learn More →</a>
						</ui.Button>
					</div>
				</div>

				<h2 className="mb-4 font-bold text-indigo-900 text-2xl">
					💰 Cost Savings and Efficiency Gains
				</h2>
				<div className="mb-12">
					<p>
						The financial impact of implementing patient-initiated follow-up
						with pain diaries is substantial. The study calculated potential
						savings based on avoided appointments:
					</p>

					<ui.Table className="my-6">
						<ui.TableHeader className="bg-indigo-100 border-indigo-200 border-b">
							<ui.TableRow>
								<ui.TableHead>Approach</ui.TableHead>
								<ui.TableHead>Follow-ups Required</ui.TableHead>
								<ui.TableHead>Cost (per 100 patients)</ui.TableHead>
							</ui.TableRow>
						</ui.TableHeader>
						<ui.TableBody>
							<ui.TableRow>
								<ui.TableCell>Standard 6-8 Week</ui.TableCell>
								<ui.TableCell>100 appointments</ui.TableCell>
								<ui.TableCell>£10,000-£20,000</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow>
								<ui.TableCell>With PIFU & Pain Diary</ui.TableCell>
								<ui.TableCell>9 appointments</ui.TableCell>
								<ui.TableCell>£900-£1,800</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow className="bg-indigo-50 border border-indigo-200">
								<ui.TableCell className="font-bold">
									Potential Savings
								</ui.TableCell>
								<ui.TableCell className="font-bold">
									91 appointments
								</ui.TableCell>
								<ui.TableCell className="font-bold">
									£9,100-£18,200
								</ui.TableCell>
							</ui.TableRow>
						</ui.TableBody>
					</ui.Table>

					<p>
						With Patient Watch's digital solution, these savings can be even
						greater through:
					</p>

					<div className="flex md:flex-row flex-col gap-4 my-6">
						<div className="flex-1 bg-white shadow-sm p-6 border border-indigo-200 rounded-lg text-center">
							<span className="block mb-2 font-bold text-indigo-600 text-4xl">
								91%
							</span>
							<h4 className="mb-1 font-semibold">
								Reduction in Unnecessary Appointments
							</h4>
							<p className="text-gray-600 text-sm">Streamlined patient care</p>
						</div>
						<div className="flex-1 bg-white shadow-sm p-6 border border-indigo-200 rounded-lg text-center">
							<span className="block mb-2 font-bold text-indigo-600 text-4xl">
								£19K
							</span>
							<h4 className="mb-1 font-semibold">Potential Savings</h4>
							<p className="text-gray-600 text-sm">Per 100 patients annually</p>
						</div>
						<div className="flex-1 bg-white shadow-sm p-6 border border-indigo-200 rounded-lg text-center">
							<span className="block mb-2 font-bold text-indigo-600 text-4xl">
								5,000
							</span>
							<h4 className="mb-1 font-semibold">Annual Injections</h4>
							<p className="text-gray-600 text-sm">At Royal Surrey Hospital</p>
						</div>
					</div>
				</div>

				<section>
					<h2>🔄 Beyond Foot and Ankle: Wider Applications</h2>
					<div className="">
						<p className="mb-6">
							While this study focused on foot and ankle injections, Patient
							Watch's digital pain diary solution extends to multiple medical
							specialties. Our platform helps track patient outcomes across:
						</p>

						<h3 className="mb-4 font-bold text-indigo-900 text-xl">
							🏃 Sports Medicine
						</h3>
						<div className="gap-4 grid md:grid-cols-3 mb-8">
							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">⚡</span>
									<h4 className="m-0 font-semibold">Shockwave Therapy</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Plantar fasciitis</li>
									<li>Achilles tendinopathy</li>
									<li>Tennis/golfer's elbow</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">🩸</span>
									<h4 className="m-0 font-semibold">PRP Injections</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Muscle strains</li>
									<li>Ligament injuries</li>
									<li>Return-to-play protocols</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">🏆</span>
									<h4 className="m-0 font-semibold">Performance Tracking</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Rehabilitation progress</li>
									<li>Athletic recovery</li>
									<li>Training load management</li>
								</ul>
							</div>
						</div>

						<h3 className="mb-4 font-bold text-indigo-900 text-xl">
							🩺 Pain Management
						</h3>
						<div className="gap-4 grid md:grid-cols-3 mb-8">
							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">💉</span>
									<h4 className="m-0 font-semibold">Steroid Injections</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Joint inflammation</li>
									<li>Trigger point therapy</li>
									<li>Epidural administration</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">🔌</span>
									<h4 className="m-0 font-semibold">Nerve Blocks</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Peripheral nerve blocks</li>
									<li>Facet joint injections</li>
									<li>Radiofrequency ablation</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">💊</span>
									<h4 className="m-0 font-semibold">Medication Efficacy</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Opioid reduction</li>
									<li>Multimodal therapy</li>
									<li>Chronic pain patterns</li>
								</ul>
							</div>
						</div>

						<h3 className="mb-4 font-bold text-indigo-900 text-xl">
							🦴 Orthopaedics
						</h3>
						<div className="gap-4 grid md:grid-cols-3 mb-8">
							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl"></span>
									<h4 className="m-0 font-semibold">Joint Procedures</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Knee replacements</li>
									<li>Hip arthroplasty</li>
									<li>Shoulder reconstruction</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl">🔨</span>
									<h4 className="m-0 font-semibold">Fracture Management</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Post-surgical healing</li>
									<li>Non-union monitoring</li>
									<li>Weight-bearing progression</li>
								</ul>
							</div>

							<div className="bg-indigo-50 shadow-sm p-4 border border-indigo-200 rounded-lg">
								<div className="flex items-center mb-2">
									<span className="mr-2 text-2xl"></span>
									<h4 className="m-0 font-semibold">Surgical Outcomes</h4>
								</div>
								<ul className="space-y-1 mb-0 pl-5 text-sm list-disc">
									<li>Recovery milestones</li>
									<li>Functional improvement</li>
									<li>Patient satisfaction</li>
								</ul>
							</div>
						</div>

						<div className="bg-indigo-100 shadow mt-8 p-5 border border-indigo-300 rounded-lg">
							<div className="flex items-start">
								<span className="mt-1 mr-3 text-3xl">🔍</span>
								<div>
									<h3 className="mb-2 font-bold text-indigo-900 text-xl">
										MSK Radiology: Supporting All Specialties
									</h3>
									<p className="mb-2">
										Musculoskeletal radiology provides crucial diagnostic
										support across all these specialties, with Patient Watch
										helping to correlate imaging findings with patient-reported
										outcomes.
									</p>
								</div>
							</div>
						</div>

						<div className="bg-indigo-900 shadow mt-8 p-5 border border-indigo-700 rounded-lg text-white">
							<div className="flex items-start">
								<span className="mt-1 mr-3 text-3xl">💡</span>
								<div>
									<h3 className="mt-0 mb-2 font-bold text-white text-xl">
										Potential Impact at Scale
									</h3>
									<p className="mb-4">
										While this study was conducted by a foot and ankle team at
										Royal Surrey, the principles could apply to all injection
										treatments across medical specialties:
									</p>
									<div className="gap-4 grid md:grid-cols-2 mb-4">
										<div className="bg-indigo-800 p-4 border border-indigo-700 rounded-lg">
											<h4 className="mt-0 mb-2 pt-0 font-semibold text-indigo-50 text-lg">
												Current Scale at Royal Surrey
											</h4>
											<ul className="space-y-2 pl-4 list-disc">
												<li>
													Approximately 5,000 injections performed annually
												</li>
												<li>
													Based on study findings, potential for significant
													reduction in unnecessary follow-ups
												</li>
												<li>Could improve clinic capacity and waiting times</li>
											</ul>
										</div>
										<div className="bg-indigo-800 p-4 border border-indigo-700 rounded-lg">
											<h4 className="mt-0 mb-2 pt-0 font-semibold text-indigo-50 text-lg">
												Potential Applications
											</h4>
											<ul className="space-y-2 pl-4 list-disc">
												<li>Image-guided injections</li>
												<li>Joint and soft tissue procedures</li>
												<li>Therapeutic and diagnostic injections</li>
												<li>Multi-specialty implementation</li>
											</ul>
										</div>
									</div>
									<p className="bg-indigo-800 p-3 border border-indigo-700 rounded rounded-lg text-white text-sm">
										<strong className="font-bold text-white">Note:</strong> The
										original study demonstrated savings of £9,500-£19,000 per
										100 patients. Further research is needed to validate these
										findings across different specialties and at larger scales.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<h2 className="mt-12 mb-4 font-bold text-indigo-900 text-2xl">
					🛠️ Implementation Challenges and Solutions
				</h2>
				<div className="mb-12">
					<p>
						The study identified several challenges to implementing
						patient-initiated follow-up with pain diaries. Here's how Patient
						Watch addresses each one:
					</p>

					<ui.Table className="my-6">
						<ui.TableHeader className="bg-indigo-100 border-indigo-200 border-b">
							<ui.TableRow>
								<ui.TableHead>Challenge</ui.TableHead>
								<ui.TableHead>Patient Watch Solution</ui.TableHead>
							</ui.TableRow>
						</ui.TableHeader>
						<ui.TableBody>
							<ui.TableRow>
								<ui.TableCell>Patient Engagement</ui.TableCell>
								<ui.TableCell>
									User-friendly mobile app with gamification elements (92%
									compliance rate)
								</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow>
								<ui.TableCell>Digital Literacy</ui.TableCell>
								<ui.TableCell>
									Simple interface with optional caregiver access for less
									tech-savvy patients
								</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow>
								<ui.TableCell>Vulnerable Patients</ui.TableCell>
								<ui.TableCell>
									Automated alerts for non-responders with proactive outreach
									protocols
								</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow>
								<ui.TableCell>Cultural Change</ui.TableCell>
								<ui.TableCell>
									Comprehensive implementation support and staff training
									program
								</ui.TableCell>
							</ui.TableRow>
							<ui.TableRow>
								<ui.TableCell>Data Accuracy</ui.TableCell>
								<ui.TableCell>
									Time-stamped entries prevent retrospective completion
								</ui.TableCell>
							</ui.TableRow>
						</ui.TableBody>
					</ui.Table>

					<div className="bg-indigo-50 my-6 p-5 border border-indigo-200 rounded-lg">
						<h4 className="mb-2 font-semibold text-lg">
							💡 Implementation Considerations
						</h4>
						<p className="mb-3">
							When implementing a digital pain diary and PIFU system,
							organizations might expect to see:
						</p>
						<ul className="mb-3 pl-5 list-disc">
							<li>Reduction in unnecessary follow-up appointments</li>
							<li>
								Potential cost savings from optimized appointment scheduling
							</li>
							<li>Improved clinic capacity for new patients</li>
							<li>Better patient engagement in their care journey</li>
						</ul>
						<ui.Button asChild variant="link" className="p-0">
							<a href="/contact">Implement digital pain diaries →</a>
						</ui.Button>
					</div>
				</div>

				<h2 className="mb-4 font-bold text-indigo-900 text-2xl">
					🎯 Conclusion and Next Steps
				</h2>
				<div className="mb-12">
					<p>
						The Royal Surrey study provides compelling evidence that
						patient-initiated follow-up with pain diaries can dramatically
						improve efficiency and patient care after orthopedic injections. By
						moving away from arbitrary follow-up schedules to a patient-centered
						approach, healthcare providers can:
					</p>

					<ul className="mb-6">
						<li>Reduce unnecessary appointments by up to 91%</li>
						<li>Save £9,500-£19,000 per 100 patients annually</li>
						<li>Improve patient satisfaction and engagement</li>
						<li>Gather valuable data on treatment outcomes</li>
						<li>Allocate clinical resources more effectively</li>
					</ul>

					<p>
						Patient Watch's digital pain diary solution builds on these
						findings, addressing the limitations of paper-based systems while
						enhancing the benefits of patient-initiated follow-up. By combining
						clinical research with innovative technology, we're helping
						healthcare providers deliver more efficient, patient-centered care.
					</p>
				</div>

				<div className="bg-gray-50 my-8 p-4 border border-gray-200 rounded-lg">
					<h3 className="mb-2 font-semibold text-lg">📚 References</h3>
					<p className="text-sm">
						Fan, K.S., Haq, I., Taha, A. et al. (2025). Efficient
						patient-initiated follow-up after orthopaedic injections.{" "}
						<i>European Journal of Orthopaedic Surgery & Traumatology</i>, 35,
						77.
						<a
							href="https://link.springer.com/article/10.1007/s00590-025-04193-9"
							target="_blank"
							rel="noopener noreferrer"
							className="inline-flex items-center ml-2 text-indigo-600 hover:text-indigo-800"
						>
							https://doi.org/10.1007/s00590-025-04193-9
							<ExternalLink className="ml-1 w-3 h-3" />
						</a>
					</p>
				</div>

				<ui.Card className="bg-indigo-50 shadow-sm mt-8 border border-indigo-200">
					<ui.CardHeader>
						<ui.CardTitle className="mt-0 pt-2 text-indigo-900">
							Transform Your Practice with Digital Pain Tracking
						</ui.CardTitle>
						<ui.CardDescription>
							Join the healthcare providers who are revolutionizing patient care
							with digital pain diaries. Book a demo to see how Patient Watch
							can help you:
							<ul className="space-y-2 mt-4">
								<li>✓ Reduce unnecessary appointments by up to 91%</li>
								<li>✓ Save thousands in healthcare costs</li>
								<li>✓ Improve patient satisfaction</li>
								<li>✓ Make data-driven clinical decisions</li>
							</ul>
						</ui.CardDescription>
					</ui.CardHeader>
					<ui.CardContent>
						<div className="flex sm:flex-row flex-col items-center gap-4">
							<ui.Button asChild>
								<a href="/contact">📞 Request a Demo</a>
							</ui.Button>
							<ui.Button asChild variant="outline">
								<a href="/use-cases/pain-injections">📱 Learn More</a>
							</ui.Button>
						</div>
					</ui.CardContent>
				</ui.Card>

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Article",
						headline:
							"Digital Pain Diaries: Reducing Unnecessary Follow-ups by 91%",
						author: {
							"@type": "Organization",
							name: "Patient Watch",
						},
						datePublished: "2024-01-01",
						description:
							"Learn how digital pain diaries can reduce unnecessary orthopedic follow-up appointments by 91% and save £19,000 per 100 patients.",
						image: DesktopMobile,
					})}
				</script>
			</div>
		</>
	);
};

export default DigitalHealthTracking;

export const Route = createFileRoute(
	"/_website/blog/efficient-patient-initiated-follow-up-after-orthopaedic-injections",
)({
	component: DigitalHealthTracking,
});
