import { cn } from "@/lib/utils";
import { useState } from "react";

interface RatingOption {
	value: string;
	label: string;
}

interface Props {
	options: RatingOption[];
	orientation: "vertical" | "horizontal";
	value: string;
	onChange: (value: string) => void;
	name: string;
}

const RatingOptions = ({
	options,
	orientation,
	value,
	onChange,
	name,
}: Props) => {
	const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

	if (orientation === "vertical") {
		return (
			<div className="space-y-3 pt-2" role="radiogroup" aria-label={name}>
				{options.map((option, index) => (
					<button
						type="button"
						key={option.value}
						onClick={() => onChange(option.value)}
						onFocus={() => setFocusedIndex(index)}
						onBlur={() => setFocusedIndex(null)}
						className={cn(
							"w-full flex items-center gap-3 p-3 rounded-lg transition-all",
							"hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-indigo-500",
							value === option.value && "bg-indigo-50",
						)}
					>
						<div
							className={cn(
								"h-10 w-10 rounded-full flex items-center justify-center text-lg font-medium transition-colors",
								value === option.value
									? "bg-indigo-600 text-white"
									: "bg-white border-2 border-slate-300 text-slate-700",
							)}
						>
							{option.value}
						</div>
						<span className="text-slate-700 text-sm">{option.label}</span>
					</button>
				))}
			</div>
		);
	}

	return (
		<div className="space-y-8 pt-4" role="radiogroup" aria-label={name}>
			{/* Rating buttons */}
			<div className="flex justify-between items-start gap-2 px-1">
				{options.map((option, index) => (
					<div
						key={option.value}
						className="flex flex-col items-center gap-2 min-w-[4rem]"
					>
						<button
							type="button"
							onClick={() => onChange(option.value)}
							onFocus={() => setFocusedIndex(index)}
							onBlur={() => setFocusedIndex(null)}
							className={cn(
								"h-12 w-12 rounded-full flex items-center justify-center text-lg transition-all",
								"hover:bg-indigo-50 hover:text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
								value === option.value
									? "bg-indigo-600 hover:text-indigo-600 text-white shadow-md scale-110"
									: "bg-white border-2 border-slate-300 text-slate-700",
								focusedIndex === index &&
									"ring-2 ring-indigo-500 ring-offset-2",
							)}
						>
							{option.value}
						</button>
						<span className="text-slate-600 text-sm text-center whitespace-normal">
							{option.label}
						</span>
					</div>
				))}
			</div>

			{/* Visual scale line */}
			<div className="relative mx-6">
				<div className="top-1/2 absolute bg-slate-200 rounded-full w-full h-1 -translate-y-1/2" />
				<div
					className="top-1/2 absolute bg-indigo-600 rounded-full h-1 -translate-y-1/2"
					style={{
						width: value
							? `${(Number(value) / (options.length - 1)) * 100}%`
							: "0%",
						transition: "width 0.3s ease-in-out",
					}}
				/>
			</div>
		</div>
	);
};

export default RatingOptions;
