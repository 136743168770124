import { commonComponents as cc } from "@/components/common";
import { getDiaryById } from "@/features/diary/api/getDiaryById";
import { entryComponents as ec } from "@/features/entries/components";
import useExtractEntry from "@/features/entries/hooks/useExtractEntry";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/_app/diary/$diaryId/edit/tracker/$trackerId/entry/$entryId/",
)({
	component: EditEntry,
	loader: async ({ params }) => {
		const { diaryId } = params;
		// Load the diary data - this will trigger the RouterSpinner
		await getDiaryById(diaryId);
		return { success: true };
	},
});

function EditEntry() {
	const { diaryId, trackerId, entryId } = Route.useParams();
	const entry = useExtractEntry(diaryId, trackerId, entryId);

	return (
		<div className="">
			<cc.Title words="Entry" />
			<ec.EditEntry entry={entry} />
			<ec.DeleteEntryButton diaryId={diaryId} entryId={entryId} />
		</div>
	);
}
