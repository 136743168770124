import type { EntryValueViewT } from "@/types/database/entries";

export const sortEntryValuesView = (entryValuesView: EntryValueViewT[]) => {
	return entryValuesView
		?.filter((ev) => ev.page_index !== null)
		?.sort(
			(a, b) =>
				min1(a.page_index) * min1(a.input_index) -
				min1(b.page_index) * min1(b.input_index),
		);
};

const min1 = (n: number | undefined | null) => {
	return n ?? 1;
};
