import { Skeleton } from "@/components/ui/skeleton";
/**
 * EntryValueScaleRating Component
 *
 * This component fetches an input by ID and displays the corresponding label
 * for a numeric value from the input's scale_options. It's designed to work with
 * the EntryValueSwitch component for displaying rating scale values.
 *
 * The component:
 * 1. Fetches the input data using the provided inputId
 * 2. Extracts scale options from the input data
 * 3. Finds the option that matches the provided numberValue
 * 4. Displays the label associated with that value
 *
 * This approach allows displaying human-readable labels (like "None", "Slight", "Moderate")
 * instead of just numeric values in the UI.
 */
import { useSelectInputById } from "@/features/inputs/api/selectInputById";
import { cn } from "@/lib/utils";

interface EntryValueScaleRatingProps {
	inputId: string;
	numberValue: number | null;
	inputLabel?: string;
	className?: string;
	showValue?: boolean;
	showLabel?: boolean;
}

export default function EntryValueScaleRating({
	inputId,
	numberValue,
	inputLabel,
	className,
	showValue = true,
	showLabel = true,
}: EntryValueScaleRatingProps) {
	const { data: input, isLoading } = useSelectInputById(inputId);

	if (isLoading) {
		return <Skeleton className="w-24 h-5" />;
	}

	if (!input?.scale_options || numberValue === null) {
		return <span className={className}>{numberValue ?? "None"}</span>;
	}

	// Parse scale options if it's a string
	const scaleOptions =
		typeof input.scale_options === "string"
			? JSON.parse(input.scale_options)
			: input.scale_options;

	// Find the option with matching value
	const selectedOption = Array.isArray(scaleOptions)
		? scaleOptions.find(
				(option: { value: number; label: string }) =>
					option.value === numberValue,
			)
		: null;

	return (
		<div className={cn("flex flex-col", className)}>
			{showLabel && (
				<span className="font-medium text-slate-500 dark:text-slate-400 text-sm">
					{inputLabel}
				</span>
			)}
			<div className="flex items-center gap-1">
				{showValue && numberValue !== undefined && (
					<span className="font-medium">{numberValue}</span>
				)}
				{selectedOption?.label && (
					<span className="text-slate-700 dark:text-slate-200">
						{` - ${selectedOption.label}`}
					</span>
				)}
			</div>
		</div>
	);
}
