import { supabaseClient } from "@/lib/supabase";
import type { InputT } from "@/types/database/forms";
import { queryOptions, useQuery } from "@tanstack/react-query";

// New input query functions
export async function selectInputById(inputId: string): Promise<InputT> {
	const { data, error } = await supabaseClient
		.from("inputs")
		.select()
		.eq("input_id", inputId)
		.single();

	if (error) throw error;
	return data as InputT;
}

export const selectInputByIdQueryOptions = (inputId: string) =>
	queryOptions({
		queryKey: ["input", { input_id: inputId }],
		queryFn: async () => selectInputById(inputId),
	});

export function useSelectInputById(inputId: string) {
	const options = selectInputByIdQueryOptions(inputId);
	return useQuery(options);
}
